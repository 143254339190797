import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Login } from './components/Login';
import { Projects } from './components/Projects';
import { EditProject } from './components/EditProject';
import { GlobalStyle } from './GlobalStyle';
import { Layout } from './components/Layout';
import { EditReport } from './components/EditReport';
import { UserContext } from './contexts/UserContext';

import './font/stylesheet.css';

function App() {
	const { user } = useContext(UserContext);

	return (
		<>
			<GlobalStyle />
			<Layout>
				<Switch>
					<Route path={'/login'} component={Login} />
					{!user && <Redirect to={'/login'} />}
					<Route path={'/projects/new'} component={EditProject} />
					<Route path={'/report/:slug'} component={EditReport} />
					{/*<Route path={'/projects/:slug'} component={EditProject} />*/}
					<Route path={'/'} component={Projects} />
				</Switch>
			</Layout>
		</>
	);
}

export default App;
