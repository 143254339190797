import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { UserContextProvider } from './contexts/UserContext';
import { apolloClient } from './lib/apollo';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import { LayoutContextProvider } from './contexts/LayoutContext';
// @ts-ignore
// if (process.env.NODE_ENV === 'development') {
// 	const whyDidYouRender = require('@welldone-software/why-did-you-render');
// 	whyDidYouRender(React, {
// 		trackAllPureComponents: true,
// 	});
// }

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({ dsn: 'https://4a5e5a0d317642e5aa3bfc4c0b56a5ef@o275344.ingest.sentry.io/5250100' });
}

ReactDOM.render(
	// <React.StrictMode>
	<ApolloProvider client={apolloClient}>
		<BrowserRouter>
			<LayoutContextProvider>
				<UserContextProvider>
					<App />
				</UserContextProvider>
			</LayoutContextProvider>
		</BrowserRouter>
		.
	</ApolloProvider>,
	// </React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
