interface ChipStyle {
	foreground: string;
	background: string;
	children: string;
}

interface ReportStateStyle {
	[key: string]: ChipStyle;
}

const reportStates: ReportStateStyle = {
	draft: {
		foreground: '#000080',
		background: '#E0E0E0',
		children: 'Draft',
	},
	in_review: {
		foreground: '#000080',
		background: '#E0E0E0',
		children: 'In review',
	},
	published: {
		foreground: '#000080',
		background: '#E0E0E0',
		children: 'Published',
	},
};

export function reportStateToChip(state: string) {
	return reportStates[state] || reportStates['draft'];
}
