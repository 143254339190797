import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Formik, FormikProps } from 'formik';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from './Button';
import * as Sentry from '@sentry/browser';
import { EditReportProjects, EditReportProjects_projects_project_reports } from '../types/EditReportProjects';
import { HeaderArea } from '../contexts/LayoutContext';
import { UserContext } from '../contexts/UserContext';
import { USER_ROLE_ENUM } from '../lib/roles';
import { PUBLISH_REPORT_MUTATION, REPORT_QUERY, UPDATE_REPORT_MUTATION } from '../queries';
import { EditReportForm, EditReportValues } from './EditReportForm';
import { BackLink } from './header/HeaderComponents';
import { UpdateReport, UpdateReportVariables } from '../types/UpdateReport';
import { SubmitReview, SubmitReviewVariables } from '../types/SubmitReview';
import { PublishReport, PublishReportVariables } from '../types/PublishReport';
import { ENUM_PROJECTREPORTS_STATE } from '../types/globalTypes';
import * as Yup from 'yup';

const Wrapper = styled.main`
	display: flex;
	flex-flow: column;
`;

const Row = styled.div`
	display: flex;
	flex-flow: row;
	justify-content: center;
`;

const Column = styled.div`
	display: flex;
	flex-flow: column;
	flex: 1;
	max-width: 50%;
`;

const YearSelect = styled.select`
	border: none;
	background: none;
	font-size: 20px;
	font-weight: 600;
	width: fit-content;
	margin: 1em 0;
`;

export const SUBMIT_REVIEW_MUTATION = gql`
	mutation SubmitReview($id: ID!) {
		sendToReview(id: $id) {
			id
			state
		}
	}
`;

const defaultValues: EditReportValues = {
	description: '',
	id: '',
	milestones: [],
	financing_sources: [],
	budget_comments: '',
	budget_total: 0,
	project_status: '',
	coherence: '',
	entities_involved: '',
	financing_comments: '',
	financing_percentage: 0,
	objectives: '',
	relevance: '',
	report_year: 0,
	technologies: '',
	countries_participating: [],
	countries_partnering: [],
	countries_proposing: [],
};

const editReportValidationSchema = Yup.object<EditReportValues>().shape({
	budget_total: Yup.number()
		.typeError('Budget total must be a number')
		.transform((v, o) => parseFloat(v || 0)),
	financing_percentage: Yup.number()
		.typeError('Financing percentage must be a number')
		.transform((v, o) => parseFloat(v || 0)),
});

export const EditReport: React.FC = () => {
	const { slug } = useParams();
	const history = useHistory();
	const formikRef = useRef<FormikProps<EditReportValues>>(null);

	const { data, loading, refetch } = useQuery<EditReportProjects>(REPORT_QUERY, { variables: { slug } });
	const [updateProject] = useMutation<UpdateReport, UpdateReportVariables>(UPDATE_REPORT_MUTATION);
	const [submitForReviewMutation] = useMutation<SubmitReview, SubmitReviewVariables>(SUBMIT_REVIEW_MUTATION);
	const [publishReport] = useMutation<PublishReport, PublishReportVariables>(PUBLISH_REPORT_MUTATION);
	const [report, setReport] = useState<EditReportProjects_projects_project_reports | null>(null);
	const { user } = useContext(UserContext);

	const project = data?.projects?.[0];
	const reports = project?.project_reports;

	const canEditReport =
		user?.role.type === USER_ROLE_ENUM.administrator || report?.state === ENUM_PROJECTREPORTS_STATE.draft;

	const loadReport = useCallback(() => {
		// After refetch we should be able to edit same item we saw previously
		const previousReportId = report?.id;
		const initialReport = previousReportId ? reports?.find((r) => r?.id === previousReportId) : reports?.[0];
		if (initialReport) {
			setReport(initialReport);
		}
	}, [report, reports]);

	useEffect(loadReport, [data]);

	if (loading) return <div>loading...</div>;

	let initialValues = defaultValues;
	if (report) {
		const {
			project_status,
			financing_sources,
			updatedAt,
			countries_proposing,
			countries_participating,
			countries_partnering,
			__typename,
			...rep
		} = report;
		initialValues = {
			...initialValues,
			...((rep as unknown) as EditReportValues),
			project_status: project_status?.id || '',
			financing_sources: financing_sources?.map(mapById) || [],
			countries_proposing: countries_proposing?.map(mapById) || [],
			countries_participating: countries_participating?.map(mapById) || [],
			countries_partnering: countries_partnering?.map(mapById) || [],
		};
	}

	function mapById(v: { id: string } | null | undefined) {
		return v?.id || '';
	}

	initialValues.isDisabled = !canEditReport;

	function handleSubmit(data: EditReportValues) {
		if (!canEditReport) return;

		const { id, milestones, project_status, budget_total, financing_percentage, ...dataCopy } = { ...data };
		delete dataCopy.__typename;
		delete dataCopy.isDisabled;

		const updatedMilestones = milestones
			.map((milestone) => {
				if (!milestone) return null;
				const newItem = {
					...milestone,
					phase: milestone.phase.id,
					__typename: '',
				};
				delete newItem.__typename;

				return newItem;
			})
			.filter((a) => a !== null);

		return updateProject({
			variables: {
				report: {
					data: {
						...dataCopy,
						budget_total: budget_total || 0,
						financing_percentage: financing_percentage || 0,
						project_status: project_status || null,
						milestones: updatedMilestones,
					},
					where: { id },
				},
			},
		})
			.then(() => refetch())
			.catch((e) => {
				console.error(e);
				Sentry.captureException(e);
			});
	}

	function submitForReviewPrompt() {
		if (
			window.confirm('After submitting for review you can not edit this report. Are you sure you want to continue?')
		) {
			formikRef?.current?.submitForm()?.then(() => {
				submitForReview();
			});
		}
	}

	function submitForReview() {
		submitForReviewMutation({ variables: { id: report?.id || '' } })
			.then(() => {
				history.replace('/projects');
			})
			.catch((e) => {
				console.error(e);
				Sentry.captureException(e);
			});
	}

	function depublish() {
		submitForReviewMutation({ variables: { id: report?.id || '' } })
			.then(() => refetch())
			.catch((e) => {
				console.error(e);
				Sentry.captureException(e);
			});
	}

	function publish() {
		publishReport({ variables: { id: report?.id || '' } })
			.then(() => {
				history.replace('/projects');
			})
			.catch((e) => {
				console.error(e);
				Sentry.captureException(e);
			});
	}

	function yearSelectionChange(ev: React.ChangeEvent<HTMLSelectElement>) {
		const id = ev.target.value;
		const selectedReport = reports?.find((r) => r?.id === id);
		if (selectedReport) {
			setReport(selectedReport);
		}
	}

	// @ts-ignore
	return (
		<Wrapper>
			<HeaderArea position={'left'}>
				<BackLink to={'/projects'}>Projects</BackLink>
			</HeaderArea>

			<HeaderArea position={'right'}>
				{user?.role?.type === USER_ROLE_ENUM.editor && report?.state === ENUM_PROJECTREPORTS_STATE.draft && (
					<Button color={'success'} onClick={submitForReviewPrompt}>
						Submit for review
					</Button>
				)}

				{user?.role?.type === USER_ROLE_ENUM.administrator && (
					<>
						{report?.state !== ENUM_PROJECTREPORTS_STATE.published && (
							<Button color={'success'} onClick={publish}>
								Publish
							</Button>
						)}
						{report?.state === ENUM_PROJECTREPORTS_STATE.published && (
							<Button color={'success'} onClick={depublish}>
								Depublish
							</Button>
						)}
					</>
				)}
			</HeaderArea>
			<HeaderArea position={'middle'}>
				Editing: {project?.title} {report?.report_year}
			</HeaderArea>

			<Row>
				<Column>
					{user?.role?.type === USER_ROLE_ENUM.editor && <h2>{report?.report_year}</h2>}

					{user?.role?.type === USER_ROLE_ENUM.administrator && (
						<YearSelect onChange={yearSelectionChange}>
							{reports?.map((rep) => {
								if (!rep) return null;
								return (
									<option key={rep.id} value={rep.id}>
										{rep.report_year}
									</option>
								);
							})}
						</YearSelect>
					)}

					<Formik
						innerRef={formikRef}
						key={`${report?.id} ${report?.updatedAt}`}
						component={EditReportForm}
						initialValues={initialValues}
						validationSchema={editReportValidationSchema}
						onSubmit={handleSubmit}
					/>
				</Column>
			</Row>
		</Wrapper>
	);
};
