import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';

export const apolloClient = new ApolloClient({
	cache: new InMemoryCache(),
	uri: process.env.REACT_APP_GRAPHQL_URI,
	request: (operation) => {
		const token = localStorage.getItem('token');

		operation.setContext({
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		});
	},
	onError: (error) => {
		if (error.graphQLErrors?.some((e) => e.message === 'Invalid token.')) {
			localStorage.clear();
		}
	},
});
