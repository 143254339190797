import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BackLink = styled(Link)`
	&::before {
		content: '←';
		margin-right: 8px;
	}
`;

export const Header = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	background: #15202b;
	padding: 11px 27px;
	color: #dedede;
	display: flex;
	justify-content: space-between;
	z-index: 2;

	a {
		color: #dedede;
		text-decoration: none;
	}
`;

export const HeaderBlock = styled.div`
	flex: 1;
	display: flex;
	align-items: flex-start;

	> *:not(:last-child) {
		margin-right: 10px;
	}
`;
