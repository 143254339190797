import styled from 'styled-components';

export const Chip = styled.span`
	color: ${(p) => p.theme.fg || 'var(--light-grey)'};
	background-color: ${(p) => p.theme.bg || 'var(--navy)'};
	border-radius: 1000px;
	padding: 6px 12px;
	font-size: 10px;
	font-weight: 600;
	line-height: 1.27em;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	width: fit-content;

	img {
		height: 10px;
		margin-right: 4px;
	}
`;
