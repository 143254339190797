import React, { useState } from 'react';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useField, useFormikContext } from 'formik';

function editorStateFromMarkdown(markdown: string) {
	const rawData = markdownToDraft(markdown);
	const contentState = convertFromRaw(rawData);
	return EditorState.createWithContent(contentState);
}

function markdownFromEditorState(editorState: EditorState) {
	const content = editorState.getCurrentContent();
	const rawObject = convertToRaw(content);
	return draftToMarkdown(rawObject);
}

const editorToolbar = {
	options: ['inline', 'list'],
	inline: {
		inDropdown: false,
		className: undefined,
		component: undefined,
		dropdownClassName: undefined,
		options: ['bold', 'italic'],
	},
	list: {
		inDropdown: false,
		className: undefined,
		component: undefined,
		dropdownClassName: undefined,
		options: ['unordered', 'ordered'],
	},
};

interface MarkdownField {
	name: string;
}

export const MarkdownField: React.FC<MarkdownField> = ({ name }) => {
	const formikContext = useFormikContext<{ isDisabled?: boolean }>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(name);

	const disabled = formikContext.values.isDisabled;

	function onChange(value: string) {
		helpers.setValue(value);
	}

	return <MarkdownEditor onChange={onChange} value={field.value} disabled={disabled} />;
};

interface MarkdownEditor {
	onChange?: (value: string) => void;
	disabled?: boolean;
	value: string;
}

export const MarkdownEditor: React.FC<MarkdownEditor> = ({ onChange, value, disabled }) => {
	const [editorState, setEditorState] = useState(editorStateFromMarkdown(value));
	const [focused, setFocused] = useState(false);

	function onEditorStateChange(val: EditorState) {
		setEditorState(val);
		const markdown = markdownFromEditorState(val);
		onChange?.(markdown);
	}

	return (
		<Editor
			toolbar={editorToolbar}
			editorState={editorState}
			onEditorStateChange={onEditorStateChange}
			readOnly={disabled}
			onFocus={(event) => {
				setFocused(true);
			}}
			onBlur={(event) => {
				setFocused(false);
			}}
			toolbarStyle={{
				marginBottom: 0,
				width: 'fit-content',
				border: '1px solid #15202B',
				borderBottom: 'none',
				borderRadius: '5px 5px 0 0',
				display: `${disabled ? 'none' : 'static'}`,
			}}
			editorStyle={{
				background: '#ffffff',
				padding: '0 18px',
				border: focused ? '2px solid var(--light-blue)' : '1px solid #15202B',
				borderRadius: `${disabled ? '5px' : 0} 5px 5px 5px`,
			}}
		/>
	);
};
