import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { useAuth } from '../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { LayoutContext } from '../contexts/LayoutContext';
import { Helpdesk } from '../components/Helpdesk';
import { Header, HeaderBlock } from './header/HeaderComponents';

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	flex-flow: column;
	padding-bottom: 30px;
	padding-top: 43px;
`;

export const Layout: React.FC = ({ children }) => {
	const { logout } = useAuth();
	const { headerSlots } = useContext(LayoutContext);
	const { user } = useContext(UserContext);
	const history = useHistory();

	function handleLogout() {
		logout();
		history.replace('/');
	}

	return (
		<Wrapper>
			<Helpdesk />
			{user && (
				<Header>
					<HeaderBlock style={{ justifyContent: 'flex-start' }}>{headerSlots.left}</HeaderBlock>
					<HeaderBlock style={{ justifyContent: 'center' }}>{headerSlots.middle}</HeaderBlock>
					<HeaderBlock style={{ justifyContent: 'flex-end' }}>
						{headerSlots.right}
						<Button onClick={handleLogout} color={'light'}>
							Log out
						</Button>
					</HeaderBlock>
				</Header>
			)}
			{children}
		</Wrapper>
	);
};
