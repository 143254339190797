import React, { useMemo } from 'react';
import { ValueType } from 'react-select/src/types';
import { ActionMeta } from 'react-select';
import { Option } from 'react-select/src/filters';
import { useField } from 'formik';
import Select from 'react-select';

export interface MultiOption {
	label?: string | null;
	value?: string | number | null;
}

interface MultiSelectField {
	options?: MultiOption[];
	name: string;
}

export const MultiSelectField: React.FC<MultiSelectField> = ({ name, options }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helper] = useField(name);
	const value = useMemo(() => options?.filter((option) => field.value.includes(option.value)), [field.value, options]);

	function onMultiChange(selectedOption: ValueType<MultiOption>, e: ActionMeta<MultiOption>) {
		handleSelectChange(selectedOption as Option[], e);
	}

	function handleSelectChange(options: MultiOption[], action: ActionMeta<MultiOption>) {
		if (!action.name) return;

		helper.setValue(options?.map((option: any) => option.value));
	}

	return (
		<Select
			name={name}
			options={options}
			isMulti={true}
			closeMenuOnSelect={false}
			onChange={onMultiChange}
			value={value}
		/>
	);
};
