import React from 'react';

export const CloseIcon = () => (
	<svg width="19" height="19" viewBox="-1 -1 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
			fill="#E4E4E4"
		/>
	</svg>
);
