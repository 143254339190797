import React, { CSSProperties } from 'react';
import { useField } from 'formik';
import styled, { css } from 'styled-components';

export const InputStyle = css`
	border: 1px solid #15202b;
	background: #ffffff;
	border-radius: 5px;
	padding: 8px 10px;
	line-height: 1;
	font-size: 14px;

	:focus {
		border: 1px solid var(--light-blue);
	}
`;

const InvalidInputStyle = css``;

interface InvalidInputProps {
	invalid?: boolean;
}

export const Input = styled.input<InvalidInputProps>`
	width: 100%;
	box-sizing: border-box;
	flex: 0;
	${InputStyle};
	${(p) => p.invalid && InvalidInputStyle}
`;

export const TextArea = styled.textarea<InvalidInputProps>`
	${InputStyle};
	width: 100%;
	box-sizing: border-box;
	${(p) => p.invalid && InvalidInputStyle}
`;

export const Select = styled.select<InvalidInputProps>`
	${InputStyle};
	${(p) => p.invalid && InvalidInputStyle};

	margin-bottom: 14px;
`;

const InputError = styled.span``;

const Label = styled.label`
	width: 100%;

	div + ${Input} {
		margin-top: 7px;
	}
`;

interface InputField {
	type?: string;
	name: string;
	label?: string;
	placeholder?: string;
	style?: CSSProperties;
	className?: string;
}

export const InputField: React.FC<InputField> = (props) => {
	const [field, meta] = useField(props.name);
	const value = field.value ?? (props.type === 'number' ? 0 : '');

	return (
		<Label className={props.className} style={props.style}>
			{props.label && <div>{props.label}</div>}
			<Input
				{...field}
				value={value}
				type={props.type || 'text'}
				invalid={!!meta.error && meta.touched}
				placeholder={props.placeholder}
			/>
			{!!meta.error && meta.touched && <InputError>{meta.error}</InputError>}
		</Label>
	);
};

interface TextAreaField {
	name: string;
	label?: string;
	placeholder?: string;
}

export const TextAreaField: React.FC<TextAreaField> = (props) => {
	const [field, meta] = useField(props.name);

	return (
		<Label>
			{props.label && <div>{props.label}</div>}
			<TextArea {...field} invalid={!!meta.error && meta.touched} placeholder={props.placeholder} />
			{!!meta.error && meta.touched && <InputError>{meta.error}</InputError>}
		</Label>
	);
};

interface SelectField {
	name: string;
	label?: string;
	placeholder?: string;
}
export const SelectField: React.FC<SelectField> = (props, children) => {
	const [field, meta] = useField(props.name);

	return (
		<Label>
			{props.label && <div>{props.label}</div>}
			<Select {...field} invalid={!!meta.error && meta.touched} placeholder={props.placeholder}>
				{children}
			</Select>
			{!!meta.error && meta.touched && <InputError>{meta.error}</InputError>}
		</Label>
	);
};
