import React, { useCallback, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Input, Select } from './Input';
import { Button } from './Button';
import styled from 'styled-components';
import { MarkdownEditor } from './MarkdownField';
import { CloseIcon } from './CloseIcon';

interface MilestonesField {
	name: string;
	phaseOptions?: (Phase | null)[];
}

const defaultMilestone: Milestone = {
	active: false,
	description: '',
	phase: { id: '', title: '' },
	span: '',
};

const MilestoneButton = styled(Button)`
	margin-left: 243px;
`;

export const MilestonesField: React.FC<MilestonesField> = ({ name, phaseOptions }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helper] = useField(name);
	const [milestones, setMilestones] = useState<Milestone[]>(field.value);
	const formikContext = useFormikContext<{ isDisabled?: boolean }>();

	const isDisabled = formikContext.values.isDisabled;

	const setNewValue = useCallback(() => {
		helper.setValue(milestones);
	}, [helper, milestones]);

	useEffect(setNewValue, [milestones]);

	function addMilestone() {
		const newMilestone = { ...defaultMilestone, phase: phaseOptions?.[0] || { id: '', title: '' } };
		setMilestones((prevState) => [...prevState, newMilestone]);
	}

	function removeMilestone(milestone: Milestone) {
		setMilestones((prevState) => {
			const newArr = [...prevState];
			const pos = newArr.indexOf(milestone);
			newArr.splice(pos, 1);
			return newArr;
		});
	}

	function milestoneChange(prevMilestone: Milestone, milestone: Milestone) {
		setMilestones((prevState) => {
			const newArr = [...prevState];
			newArr.splice(newArr.indexOf(prevMilestone), 1, milestone);
			return newArr;
		});
	}

	function changeActive(index: number) {
		setMilestones((prevState) => {
			return prevState.map((state, innerIndex) => {
				state.active = index === innerIndex;
				return state;
			});
		});
	}

	return (
		<div>
			{milestones.map((milestone, index) => {
				return (
					<MilestoneRow
						isDisabled={isDisabled}
						milestone={milestone}
						phases={phaseOptions || []}
						onRemove={() => removeMilestone(milestone)}
						onChange={(newMilestone) => milestoneChange(milestone, newMilestone)}
						onActiveChange={() => changeActive(index)}
					/>
				);
			})}
			{!isDisabled && (
				<MilestoneButton type={'button'} onClick={addMilestone}>
					Add milestone
				</MilestoneButton>
			)}
		</div>
	);
};

const Row = styled.div`
	display: flex;
	flex-flow: row;
	align-items: flex-start;

	:not(:last-child) {
		margin-bottom: 20px;
	}
`;

const Column = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column;
	margin: 0 10px 0 20px;
`;

const CloseButton = styled.div`
	box-sizing: border-box;
	display: flex;
	align-items: center;
	height: 35px;

	svg path {
		fill: var(--grey);
		transition: fill 0.2s;
	}

	&:hover {
		cursor: pointer;

		svg path {
			fill: var(--light-blue);
		}
	}
`;

const Radio = styled.input`
	display: flex;
	align-items: center;
	height: 35px;
	line-height: 14px;
	margin: 0 12px 0 -4px;
`;

interface MilestoneRow {
	milestone: Milestone;
	phases?: (Phase | null)[];
	onRemove?: () => void;
	onChange?: (milestone: Milestone) => void;
	onActiveChange?: () => void;
	isDisabled?: boolean;
}

const MilestoneRow: React.FC<MilestoneRow> = ({
	milestone = defaultMilestone,
	phases,
	onRemove,
	onChange,
	onActiveChange,
	isDisabled,
}) => {
	if (!milestone) {
		return null;
	}

	function changePhase(ev: React.ChangeEvent<HTMLSelectElement>) {
		const phase = phases?.find((p) => p?.id === ev.target.value);
		if (phase) {
			onChange?.({ ...milestone, phase });
		}
	}

	function changeSpan(ev: React.ChangeEvent<HTMLInputElement>) {
		const span = ev.target.value;
		onChange?.({ ...milestone, span });
	}

	function changeDescription(description: string) {
		onChange?.({ ...milestone, description });
	}

	return (
		<Row>
			<Radio type={'radio'} name={'active'} checked={milestone.active ?? false} onChange={onActiveChange} />
			<Input
				name={'span'}
				style={{ minWidth: 204 }}
				value={milestone.span}
				onChange={changeSpan}
				placeholder={'Time span'}
			/>
			<Column>
				<Select name={'phase'} value={milestone.phase.id} onChange={changePhase}>
					{phases?.map((phase) => {
						if (!phase) return null;
						return <option value={phase.id}>{phase.title}</option>;
					})}
				</Select>
				<MarkdownEditor value={milestone.description} onChange={changeDescription} disabled={isDisabled} />
			</Column>
			{!isDisabled && (
				<CloseButton onClick={onRemove}>
					<CloseIcon />
				</CloseButton>
			)}
		</Row>
	);
};
