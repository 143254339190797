import React, { CSSProperties } from 'react';
import { Chip } from './Chip';

interface PreChip {
	foreground?: string | null;
	background?: string | null;
	className?: string;
	style?: CSSProperties;
}

export const PreChip: React.FC<PreChip> = ({ foreground, background, children, className, style }) => {
	return (
		<Chip theme={{ fg: foreground, bg: background }} className={className} style={style}>
			{children}
		</Chip>
	);
};
