import { Button } from '../Button';
import React from 'react';

interface SaveButton {
	isSaving?: boolean;
	onClick?: () => void;
}

export const SaveButton: React.FC<SaveButton> = ({ isSaving, onClick }) => {
	return (
		<Button color={'secondary'} onClick={onClick} disabled={isSaving}>
			{isSaving ? 'Saving' : 'Save'}
		</Button>
	);
};
