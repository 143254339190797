import React, { createRef } from 'react';
import styled from 'styled-components';
import { Formik, FormikProps } from 'formik';
import { InputField } from './Input';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Select, { ActionMeta } from 'react-select';
import { Button } from './Button';
import { HeaderArea } from '../contexts/LayoutContext';
import { BackLink } from './header/HeaderComponents';
import { CREATE_NEW_PROJECT_REPORT_MUTATION, CREATE_PROJECT_MUTATION, EDIT_PROJECT_OPTIONS_QUERY } from '../queries';
import { ValueType } from 'react-select/src/types';
import { Option } from 'react-select/src/filters';
import { SaveButton } from './form/SaveButton';
import { useHistory } from 'react-router-dom';
import { GetEditProjectOptions } from '../types/GetEditProjectOptions';
import { CreateProjectMutation, CreateProjectMutationVariables } from '../types/CreateProjectMutation';
import { CreateNewProjectReport, CreateNewProjectReportVariables } from '../types/CreateNewProjectReport';
import { ENUM_PROJECTREPORTS_STATE, ENUM_PROJECT_PRIORITY } from '../types/globalTypes';

const Wrapper = styled.main`
	padding-top: 20px;
	display: flex;
	flex-flow: column;
	background: #dedede;
`;

const Row = styled.div`
	display: flex;
	flex-flow: row;
	justify-content: center;
`;

const Column = styled.div`
	display: flex;
	flex-flow: column;
	flex: 1;
	max-width: 50%;
`;

const FormRow = styled.div`
	border-top: 1px solid #15202b;
	padding: 24px 0;
	margin-top: 4px;
	display: flex;

	> * {
		flex: 1;
	}
`;

const Title = styled.span`
	display: block;
	font-size: 14px;
	margin-bottom: 12px;
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const TitleInput = styled.input`
	border: none;
	background: none;
	outline: none;
	font-size: 50px;
	line-height: 1.27;
	color: var(--navy);
	flex: 1;
	max-width: 100%;
`;

interface EditProjectFormProps {
	title: string;
	subtitle: string;
	sectors: string[];
	assignees: string[];
}

const initialValues: EditProjectFormProps = {
	title: '',
	subtitle: '',
	sectors: [],
	assignees: [],
};

interface MultiOption {
	label?: string | null;
	value?: string | number | null;
}

const EditProjectForm: React.FC<FormikProps<EditProjectFormProps>> = ({
	handleSubmit,
	submitForm,
	values,
	handleChange,
	setFieldValue,
	isSubmitting,
}) => {
	const { data } = useQuery<GetEditProjectOptions>(EDIT_PROJECT_OPTIONS_QUERY);
	const titleRef = createRef<HTMLInputElement>();

	const usersOptions: MultiOption[] = data?.users?.map((user) => ({ value: user?.id, label: user?.email })) || [];
	const sectorOptions: MultiOption[] =
		data?.sectors?.map((sector) => ({ value: sector?.id, label: sector?.title })) || [];
	const priorityOptions: MultiOption[] =
		Object.keys(ENUM_PROJECT_PRIORITY).map((item) => ({ value: item, label: item })) || [];

	function onMultiChange(selectedOption: ValueType<MultiOption>, e: ActionMeta<MultiOption>) {
		handleSelectChange(selectedOption as Option[], e);
	}

	function handleSelectChange(options: MultiOption[] | MultiOption, action: ActionMeta<MultiOption>) {
		if (!action.name) return;

		if (!Array.isArray(options)) {
			setFieldValue(action.name, options.value);
		} else {
			setFieldValue(
				action.name,
				options?.map((option: any) => option.value)
			);
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<TitleWrapper>
				<TitleInput
					name={'title'}
					ref={titleRef}
					placeholder={'Project title...'}
					value={values.title}
					onChange={handleChange}
				/>
				<Button onClick={() => titleRef?.current?.focus()}>Edit&nbsp;title</Button>
			</TitleWrapper>
			<FormRow style={{ border: 'none' }}>
				<InputField style={{ flex: 1 }} name={'subtitle'} placeholder={'Project subtitle'} />
			</FormRow>
			<FormRow>
				<Title>Sectors</Title>
				<Select
					name={'sectors'}
					options={sectorOptions}
					isMulti={true}
					closeMenuOnSelect={false}
					onChange={onMultiChange}
				/>
			</FormRow>
			<FormRow>
				<Title>Project assigned to</Title>
				<Select
					name={'assignees'}
					options={usersOptions}
					isMulti={true}
					closeMenuOnSelect={false}
					onChange={onMultiChange}
				/>
			</FormRow>

			<FormRow>
				<Title>Project priority</Title>
				<Select
					name={'priority'}
					options={priorityOptions}
					isMulti={false}
					closeMenuOnSelect={true}
					onChange={onMultiChange}
				/>
			</FormRow>

			<HeaderArea position={'right'}>
				<SaveButton isSaving={isSubmitting} onClick={submitForm} />
			</HeaderArea>
		</form>
	);
};

export const EditProject: React.FC = () => {
	const history = useHistory();
	const [createProject] = useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CREATE_PROJECT_MUTATION);
	const [createProjectReport] = useMutation<CreateNewProjectReport, CreateNewProjectReportVariables>(
		CREATE_NEW_PROJECT_REPORT_MUTATION
	);

	async function handleSubmit(data: EditProjectFormProps) {
		return createProject({ variables: { project: { data } } }).then((response) => {
			const id = response.data?.createProject?.project?.id;

			if (id) {
				createProjectReport({
					variables: {
						report: {
							data: { project: id, report_year: new Date().getFullYear(), state: ENUM_PROJECTREPORTS_STATE.draft },
						},
					},
				}).then((reportResponse) => {
					const slug = response.data?.createProject?.project?.slug;
					history.replace(`/report/${slug}`);
				});
			}
		});
	}

	return (
		<Wrapper>
			<HeaderArea position={'left'}>
				<BackLink to={'/projects'}>Projects</BackLink>
			</HeaderArea>
			<Row>
				<Column>
					<Formik component={EditProjectForm} initialValues={initialValues} onSubmit={handleSubmit} />
				</Column>
			</Row>
		</Wrapper>
	);
};
