import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { PreChip } from './PreChip';
import { GetMyProjects, GetMyProjects_myProjects } from '../types/GetMyProjects';
import { HeaderArea } from '../contexts/LayoutContext';
import { Button } from './Button';
import { UserContext } from '../contexts/UserContext';
import { USER_ROLE_ENUM } from '../lib/roles';
import { CellProps, Column } from 'react-table';
import { Table } from './Table';
import { CREATE_NEW_REPORT_MUTATION, REQUEST_PROJECT_REPORTS } from '../queries';
import { GenerateReport, GenerateReportVariables } from '../types/GenerateReport';
import { reportStateToChip } from '../lib/stateToChip';
import moment from 'moment';
import { RequestProjectReports, RequestProjectReportsVariables } from '../types/RequestProjectReports';

const Wrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	max-width: 1200px;
`;

const ActionsPanel = styled.div`
	${Button} {
		:not(:last-child) {
			margin-right: 12px;
		}
	}
`;

const PROJECTS_QUERY = gql`
	query GetMyProjects {
		myProjects {
			id
			slug
			title
			registered_year
			updatedAt
			assignees {
				id
				email
			}
			project_reports(limit: 1, sort: "report_year:DESC") {
				updatedAt
				state
				report_year
				project_status {
					title
					background
					foreground
				}
			}
		}
	}
`;

export const Projects: React.FC = () => {
	const history = useHistory();
	const { user } = useContext(UserContext);
	const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
	const { data, refetch } = useQuery<GetMyProjects>(PROJECTS_QUERY);
	const [createNewReport] = useMutation<GenerateReport, GenerateReportVariables>(CREATE_NEW_REPORT_MUTATION);
	const [requestProjectReports] = useMutation<RequestProjectReports, RequestProjectReportsVariables>(
		REQUEST_PROJECT_REPORTS
	);

	const columns: Column<GetMyProjects_myProjects>[] = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: (originalRow) => originalRow.title,
			},
			{ Header: 'Year registered', accessor: (originalRow) => originalRow.registered_year },
			// {
			// 	Header: 'Project Status',
			// 	accessor: (originalRow) => {
			// 		return originalRow.project_reports?.[0]?.project_status?.title;
			// 	},
			// 	Cell: (item: CellProps<GetMyProjects_myProjects>) => {
			// 		const projectStatus = item.cell.row.original.project_reports?.[0]?.project_status;
			// 		return <PreChip {...projectStatus}>{projectStatus?.title || '-'}</PreChip>;
			// 	},
			// },
			{ Header: 'Latest report', accessor: (originalRow) => originalRow.project_reports?.[0]?.report_year },
			{
				Header: 'Report Status',
				accessor: (originalRow) => originalRow.project_reports?.[0]?.state,
				Cell: (item: CellProps<GetMyProjects_myProjects>) => {
					const state = item.cell.row.original.project_reports?.[0]?.state;
					return <PreChip {...reportStateToChip(state || '-')} />;
				},
			},
			{
				Header: 'Edited',
				accessor: (originalRow) => new Date(originalRow.project_reports?.[0]?.updatedAt).getTime(),
				Cell: (item: CellProps<GetMyProjects_myProjects>) => {
					const updateDate = moment(item.cell.row.original.project_reports?.[0]?.updatedAt);
					return (
						<>
							{updateDate.format('LL')}
							<br />
							{updateDate.format('HH:mm')}
						</>
					);
				},
			},
		],
		[]
	);

	const myProjects = useMemo(
		() => (data?.myProjects?.filter((a) => a !== null) || []) as GetMyProjects_myProjects[],
		[data]
	);

	useEffect(() => {
		refetch();
	}, [refetch, history.location.pathname]);

	function generateReport() {
		if (!selectedProjectIds.length) {
			alert('Please select the projects to include in the report!');
			return;
		}

		const year = parseInt(
			prompt('For what year would you like to have the report?', new Date().getFullYear().toString()) || ''
		);

		if (!year) {
			alert('Year is required to create the report!');
			return;
		}

		createNewReport({ variables: { projects: selectedProjectIds, year } }).then((r) => {
			const successMessage = r.data?.generateReport
				?.map((report) => `${report?.type}: ${report?.projects_count || 0}`)
				?.join('\n');

			alert(successMessage);
		});
	}

	function requestProjectReport() {
		if (!selectedProjectIds.length) {
			alert('Please select the projects to request the report for!');
			return;
		}

		const year = parseInt(
			prompt('For what year would you like to have the report?', new Date().getFullYear().toString()) || ''
		);

		if (!year) {
			alert('Year is required to request the report!');
			return;
		}

		requestProjectReports({ variables: { projects: selectedProjectIds, year } }).then((r) => {
			const count = r.data?.createReportsForProjects?.length || 0;
			const successMessage = `Created ${count} ${count === 1 ? 'request' : 'requests'} for year ${year}`;
			alert(successMessage);
		});
	}

	function addProject() {
		history.push('/projects/new');
	}

	const onRowChange = useCallback((selectedItems: GetMyProjects_myProjects[]) => {
		setSelectedProjectIds(selectedItems.map((item) => item.id));
	}, []);

	function handleRowClick(item: GetMyProjects_myProjects) {
		history.push(`/report/${item.slug}`);
	}

	return (
		<Wrapper>
			<HeaderArea position={'right'}>
				{user?.role.type === USER_ROLE_ENUM.administrator && (
					<Button color={'secondary'} onClick={addProject}>
						New project
					</Button>
				)}
			</HeaderArea>

			<h1>Projects</h1>

			<ActionsPanel>
				{user?.role?.type === 'administrator' && (
					<>
						<Button onClick={generateReport}>Create status report</Button>
						<Button onClick={requestProjectReport}>Request project report</Button>
					</>
				)}
				{/*{selectedProjectIds.length > 0 && user?.role.type === 'editor' && (
					<>
						<Button onClick={submitForReview}>Submit for review</Button>
					</>
				)}*/}
			</ActionsPanel>

			<Table
				data={myProjects}
				columns={columns}
				onSelectedRowsChange={onRowChange}
				onRowClick={handleRowClick}
				defaultSortBy={'Name'}
			/>
		</Wrapper>
	);
};
