import React, { createContext, useState } from 'react';

interface UserContext {
	user: User | null;
	setUser: (user: User | null) => void;
}

export const UserContext = createContext<UserContext>({} as UserContext);

export const UserContextProvider: React.FC = ({ children }) => {
	const localStorageUser = JSON.parse(localStorage.getItem('user') || 'null') as User | null;
	const [user, setUser] = useState<User | null>(localStorageUser);

	return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
