import styled from 'styled-components';
import React from 'react';
import { FormikProps } from 'formik';
import { useQuery } from '@apollo/react-hooks';
import { EDIT_REPORT_OPTIONS_QUERY } from '../queries';
import { MarkdownField } from './MarkdownField';
import { MilestonesField } from './MilestonesField';
import { InputField } from './Input';
import { HeaderArea } from '../contexts/LayoutContext';
import { FieldHelp } from './FieldHelp';
import fieldHelp from '../fieldHelp.json';
import { Prompt } from 'react-router-dom';
import { SaveButton } from './form/SaveButton';
import { MultiSelectField } from './form/MultiSelectField';
import { GetEditReportOptions } from '../types/GetEditReportOptions';

const Form = styled.form``;
const Title = styled.span`
	display: block;
	font-size: 14px;
`;

const FormRow = styled.div`
	border-top: 1px solid #15202b;
	padding: 24px 0;
	margin-top: 4px;
`;

const FormRowHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
	align-items: flex-start;
`;

const BudgetRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 24px;
	flex-wrap: wrap;
`;

const Fieldset = styled.fieldset`
	border: none;
	padding: 0;
	margin: 0;
`;

const SingleRowInput = styled.div`
	display: flex;
	align-items: center;

	label {
		margin-right: 8px;
	}
`;

const InputSmall = styled(InputField)`
	input {
		max-width: 80px;
	}
`;

export interface EditReportValues {
	description: string;
	id: string;
	milestones: Milestone[];
	project_status: string;
	budget_comments: string;
	budget_total: number;
	coherence: string;
	entities_involved: string;
	financing_comments: string;
	financing_percentage: number;
	financing_sources: string[];
	objectives: string;
	relevance: string;
	report_year: number;
	technologies: string;
	isDisabled?: boolean;
	countries_proposing: string[];
	countries_participating: string[];
	countries_partnering: string[];
	__typename?: string;
}

export const EditReportForm: React.FC<FormikProps<EditReportValues>> = ({
	handleSubmit,
	handleChange,
	values,
	setFieldValue,
	submitForm,
	isSubmitting,
	dirty,
	initialValues,
}) => {
	const { data: options } = useQuery<GetEditReportOptions>(EDIT_REPORT_OPTIONS_QUERY);

	const countryOptions = options?.countries?.map((country) => ({ value: country?.id, label: country?.name }));

	return (
		<>
			<Prompt when={dirty} message={'You have some unsaved changes. Are you sure you want to leave?'} />
			<Form onSubmit={handleSubmit}>
				<Fieldset disabled={initialValues.isDisabled}>
					<FormRow style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Title>Project status:</Title>
						<select name={'project_status'} onChange={handleChange} value={values.project_status || ''}>
							<option value="" />
							{options?.projectStatuses?.map((status) => (
								<option key={status?.id} value={status?.id}>
									{status?.title}
								</option>
							))}
						</select>
					</FormRow>

					<FormRow>
						<FormRowHeader>
							<Title>Countries proposing the project:</Title>
							<FieldHelp>{fieldHelp.proposing_countries}</FieldHelp>
						</FormRowHeader>
						<MultiSelectField name={'countries_proposing'} options={countryOptions} />
					</FormRow>

					<FormRow>
						<FormRowHeader>
							<Title>Participating countries (from the 3SI region):</Title>
						</FormRowHeader>
						<MultiSelectField name={'countries_participating'} options={countryOptions} />
					</FormRow>

					<FormRow>
						<FormRowHeader>
							<Title>Partner country(ies) (from outside the 3SI region):</Title>
						</FormRowHeader>
						<MultiSelectField name={'countries_partnering'} options={countryOptions} />
					</FormRow>

					<FormRow>
						<FormRowHeader>
							<Title>Main objectives of project:</Title>
							<FieldHelp>{fieldHelp.objectives}</FieldHelp>
						</FormRowHeader>
						<MarkdownField name={'objectives'} />
					</FormRow>
					<FormRow>
						<FormRowHeader>
							<Title>Short description of the project</Title>
							<FieldHelp>{fieldHelp.description}</FieldHelp>
						</FormRowHeader>
						<MarkdownField name={'description'} />
					</FormRow>
					<FormRow>
						<FormRowHeader>
							<Title>Calendar of implementation</Title>
							<FieldHelp>{fieldHelp.implementation_calendar}</FieldHelp>
						</FormRowHeader>
						<MilestonesField name={'milestones'} phaseOptions={options?.phases || []} />
					</FormRow>
					<FormRow>
						<FormRowHeader>
							<Title>
								Coherence with EU Priorities and Policies (if the project is already included in the EU programs and
								project priority lists and if it is consistent with EU principles/directives in particular concerning
								the Environment, Public Procurement and State Aid)
							</Title>
						</FormRowHeader>
						<MarkdownField name={'coherence'} />
					</FormRow>
					<FormRow>
						<FormRowHeader>
							<Title>Relevance of the project for the region</Title>
						</FormRowHeader>
						<MarkdownField name={'relevance'} />
					</FormRow>
					<FormRow>
						<FormRowHeader>
							<Title>
								Whether innovative, safe and sustainable new technologies are to be used If the project involves
								building new infrastructure or upgrading existing infrastructure If it helps build networks between
								project partners from the 3SI countries
							</Title>
						</FormRowHeader>
						<MarkdownField name={'technologies'} />
					</FormRow>
					<FormRow>
						<FormRowHeader>
							<Title>Description of entities involved (promoter, implementing entities, beneficiaries)</Title>
							<FieldHelp>{fieldHelp.entities_involved}</FieldHelp>
						</FormRowHeader>
						<MarkdownField name={'entities_involved'} />
					</FormRow>
					<FormRow>
						<FormRowHeader>
							<Title>Budget (Total cost, Secured financing and its sources, Financing gap)</Title>
							<FieldHelp>{fieldHelp.budget}</FieldHelp>
						</FormRowHeader>
						<BudgetRow>
							<InputField type={'number'} name={'budget_total'} label={'Total (€):'} />{' '}
							<SingleRowInput>
								<InputSmall type={'number'} name={'financing_percentage'} /> % of total has secured financing
							</SingleRowInput>
						</BudgetRow>
						<MarkdownField name={'budget_comments'} />
					</FormRow>
					<FormRow>
						<FormRowHeader>
							<Title>Financing sources</Title>
							<FieldHelp>{fieldHelp.financing_sources}</FieldHelp>
						</FormRowHeader>
						<CheckboxWrapper>
							{options?.financingSources?.map((source) => (
								<label key={source?.id}>
									<input
										type={'checkbox'}
										checked={values.financing_sources.includes(source?.id || '')}
										onChange={(val) =>
											setFieldValue(
												'financing_sources',
												val.target.checked
													? [...values.financing_sources, source?.id]
													: values.financing_sources.filter((s) => s !== source?.id)
											)
										}
									/>
									{source?.title}
								</label>
							))}
						</CheckboxWrapper>
					</FormRow>
					<FormRow>
						<FormRowHeader>
							<Title>Financing comments</Title>
						</FormRowHeader>
						<MarkdownField name={'financing_comments'} />
					</FormRow>
					{!initialValues.isDisabled && (
						<HeaderArea position={'right'}>
							<SaveButton isSaving={isSubmitting} onClick={submitForm} />
						</HeaderArea>
					)}
				</Fieldset>
			</Form>
		</>
	);
};
const CheckboxWrapper = styled.div`
	display: flex;
	flex-flow: column;
`;
