import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	background-color: #f1f1f1;
	border: 1px solid #000;
	border-radius: 5px;
	bottom: 1rem;
	height: auto;
	margin: 0;
	padding: 1rem;
	position: fixed;
	right: 1rem;
	width: 12rem;
	z-index: 1000;

	p {
		line-height: 1.4;
		margin: 0;
	}
`;

export const Helpdesk: React.FC = () => {
	return (
		<Wrapper>
			<p>
				<strong>Helpdesk contact</strong>
				<br />
				Deividas Žindžius
				<br />
				<a href="mailto:deividas.zindzius@urm.lt">deividas.zindzius@urm.lt</a>
			</p>
		</Wrapper>
	);
};
