import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

type Position = 'left' | 'middle' | 'right';

type HeaderSlot = {
	[key in Position]: ReactNode[];
};

interface LayoutContext {
	addToHeader: (position: Position, element: ReactNode) => void;
	removeFromHeader: (position: Position, element: ReactNode) => void;
	headerSlots: HeaderSlot;
}

export const LayoutContext = createContext({} as LayoutContext);

export const LayoutContextProvider: React.FC = ({ children }) => {
	const [headerSlots, setHeaderSlots] = useState<HeaderSlot>({ left: [], middle: [], right: [] });

	const addToHeader = useCallback((position: Position, element: ReactNode) => {
		setHeaderSlots((prevState) => {
			const newState = { ...prevState };

			newState[position] = [element, ...newState[position]];
			return newState;
		});
	}, []);

	const removeFromHeader = useCallback((position: Position, element: ReactNode) => {
		setHeaderSlots((prevState) => {
			const newState = { ...prevState };
			newState[position] = newState[position].filter((e) => e !== element);
			return newState;
		});
	}, []);

	return (
		<LayoutContext.Provider value={{ headerSlots, removeFromHeader, addToHeader }}>{children}</LayoutContext.Provider>
	);
};

interface HeaderArea {
	position: Position;
}

export const HeaderArea: React.FC<HeaderArea> = ({ children, position }) => {
	const { addToHeader, removeFromHeader } = useContext(LayoutContext);

	useEffect(() => {
		addToHeader(position, children);

		return () => removeFromHeader(position, children);
	}, [children, addToHeader, removeFromHeader, position]);

	return null;
};
