/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_PROJECTREPORTS_STATE {
	draft = 'draft',
	in_review = 'in_review',
	published = 'published',
}

export enum ENUM_PROJECT_PRIORITY {
	complementary = 'complementary',
	primary = 'primary',
}

export enum ENUM_REPORT_TYPE {
	all = 'all',
	complementary = 'complementary',
	priority = 'priority',
}

export interface ComponentProjectMilestoneInput {
	span?: string | null;
	phase?: string | null;
	description?: string | null;
	active?: boolean | null;
}

export interface InputID {
	id: string;
}

export interface ProjectInput {
	title?: string | null;
	slug?: string | null;
	subtitle?: string | null;
	registered_year?: number | null;
	assignees?: (string | null)[] | null;
	sectors?: (string | null)[] | null;
	project_reports?: (string | null)[] | null;
	priority?: ENUM_PROJECT_PRIORITY | null;
}

export interface ProjectReportInput {
	report_year?: number | null;
	objectives?: string | null;
	description?: string | null;
	milestones?: (ComponentProjectMilestoneInput | null)[] | null;
	coherence?: string | null;
	technologies?: string | null;
	entities_involved?: string | null;
	budget_total?: number | null;
	financing_percentage?: number | null;
	project?: string | null;
	financing_sources?: (string | null)[] | null;
	financing_comments?: string | null;
	budget_comments?: string | null;
	project_status?: string | null;
	relevance?: string | null;
	countries_proposing?: (string | null)[] | null;
	countries_participating?: (string | null)[] | null;
	countries_partnering?: (string | null)[] | null;
	state?: ENUM_PROJECTREPORTS_STATE | null;
}

export interface createProjectInput {
	data?: ProjectInput | null;
}

export interface createProjectReportInput {
	data?: ProjectReportInput | null;
}

export interface editComponentProjectMilestoneInput {
	id?: string | null;
	span?: string | null;
	phase?: string | null;
	description?: string | null;
	active?: boolean | null;
}

export interface editProjectReportInput {
	report_year?: number | null;
	objectives?: string | null;
	description?: string | null;
	milestones?: (editComponentProjectMilestoneInput | null)[] | null;
	coherence?: string | null;
	technologies?: string | null;
	entities_involved?: string | null;
	budget_total?: number | null;
	financing_percentage?: number | null;
	project?: string | null;
	financing_sources?: (string | null)[] | null;
	financing_comments?: string | null;
	budget_comments?: string | null;
	project_status?: string | null;
	relevance?: string | null;
	countries_proposing?: (string | null)[] | null;
	countries_participating?: (string | null)[] | null;
	countries_partnering?: (string | null)[] | null;
	state?: ENUM_PROJECTREPORTS_STATE | null;
}

export interface updateProjectReportInput {
	where?: InputID | null;
	data?: editProjectReportInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
