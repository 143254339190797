import { gql } from 'apollo-boost';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';

const LOGIN_QUERY = gql`
	mutation login($identifier: String!, $password: String!) {
		login(input: { identifier: $identifier, password: $password }) {
			jwt
			user {
				id
				role {
					id
					name
					type
				}
				username
			}
		}
	}
`;

export function useAuth() {
	const appolloClient = useApolloClient();
	const [loginMutation] = useMutation(LOGIN_QUERY);
	const { setUser } = useContext(UserContext);

	async function login(credentials: LoginCredentials) {
		return loginMutation({ variables: { ...credentials } })
			.then(({ data: { login } }) => {
				if (login.jwt) {
					localStorage.setItem('token', login.jwt);
					localStorage.setItem('user', JSON.stringify(login.user));
					setUser(login.user);

					return login.user;
				}

				throw new Error('Token was not returned from the server');
			})
			.catch(console.error);
	}

	function logout() {
		localStorage.clear();
		setUser(null);
		appolloClient.clearStore();
	}

	return { login, logout };
}
