import React from 'react';
import { Formik, FormikProps } from 'formik';
import { InputField } from './Input';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from './Button';
import { useAuth } from '../hooks/useAuth';

const Wrapper = styled.main`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	flex: 1;
`;

const Logo = styled.img`
	margin-bottom: 16vh;
`;

const Form = styled.form`
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 300px;

	> * {
		margin: 10px 0;
	}
`;

const SubmitButton = styled(Button)`
	margin-top: 8vh;
	min-width: 140px;
`;

const LoginForm: React.FC<FormikProps<LoginCredentials>> = ({ handleSubmit }) => {
	return (
		<Form onSubmit={handleSubmit}>
			<InputField type={'text'} name={'identifier'} label={'User'} />
			<InputField type={'password'} name={'password'} label={'Password'} />
			<SubmitButton color={'secondary'} size={'large'} type={'submit'}>
				Log in
			</SubmitButton>
		</Form>
	);
};

export const Login: React.FC = () => {
	const history = useHistory();
	const { login } = useAuth();

	function onSubmit(data: LoginCredentials) {
		login(data).then(() => {
			history.replace('/');
		});
	}

	return (
		<Wrapper>
			<Logo src="logo.svg" />
			<Formik initialValues={{ identifier: '', password: '' }} onSubmit={onSubmit} component={LoginForm} />
		</Wrapper>
	);
};
