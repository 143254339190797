import { gql } from 'apollo-boost';

export const REPORT_QUERY = gql`
	query EditReportProjects($slug: String!) {
		projects(where: { slug: $slug }, limit: 1) {
			id
			title
			slug
			project_reports(sort: "report_year:desc") {
				updatedAt
				state
				budget_comments
				budget_total
				coherence
				description
				entities_involved
				financing_comments
				financing_percentage
				countries_participating {
					id
					name
				}
				countries_partnering {
					id
					name
				}
				countries_proposing {
					id
					name
				}
				financing_sources {
					id
					title
				}
				id
				milestones {
					active
					description
					phase {
						id
						title
					}
					span
				}
				objectives
				project_status {
					id
					title
				}
				relevance
				report_year
				technologies
			}
		}
	}
`;

export const UPDATE_REPORT_MUTATION = gql`
	mutation UpdateReport($report: updateProjectReportInput) {
		updateProjectReport(input: $report) {
			projectReport {
				budget_comments
				budget_total
				coherence
				description
				entities_involved
				financing_comments
				financing_percentage
				financing_sources {
					id
				}
				objectives
				relevance
				report_year
				technologies
				project_status {
					id
				}
			}
		}
	}
`;

export const EDIT_REPORT_OPTIONS_QUERY = gql`
	query GetEditReportOptions {
		projectStatuses {
			id
			title
		}
		phases {
			id
			title
		}
		financingSources {
			id
			title
		}
		countries {
			id
			name
		}
	}
`;

export const PUBLISH_REPORT_MUTATION = gql`
	mutation PublishReport($id: ID!) {
		publishReport(id: $id) {
			id
			state
		}
	}
`;

export const EDIT_PROJECT_OPTIONS_QUERY = gql`
	query GetEditProjectOptions {
		sectors {
			background
			foreground
			icon
			id
			title
		}
		users {
			id
			email
			username
		}
	}
`;

export const CREATE_PROJECT_MUTATION = gql`
	mutation CreateProjectMutation($project: createProjectInput!) {
		createProject(input: $project) {
			project {
				id
				slug
			}
		}
	}
`;

export const CREATE_NEW_PROJECT_REPORT_MUTATION = gql`
	mutation CreateNewProjectReport($report: createProjectReportInput) {
		createProjectReport(input: $report) {
			projectReport {
				id
			}
		}
	}
`;

export const CREATE_NEW_REPORT_MUTATION = gql`
	mutation GenerateReport($year: Int!, $projects: [ID]) {
		generateReport(projects: $projects, year: $year) {
			projects_count
			type
		}
	}
`;

export const REQUEST_PROJECT_REPORTS = gql`
	mutation RequestProjectReports($year: Int!, $projects: [ID]!) {
		createReportsForProjects(projects: $projects, year: $year) {
			id
		}
	}
`;
