import React, { useState } from 'react';
import styled from 'styled-components';
import { screenSize } from '../ScreenSize';

const InfoItem = styled.div`
	display: flex;
	align-items: center;
	font-size: 20px;
	line-height: 1.4;

	${screenSize.sm} {
		flex-flow: row;
		justify-content: space-between;
	}
`;

interface TooltipTrigger {
	isActive: boolean;
}

const TooltipTrigger = styled.div<TooltipTrigger>`
	position: relative;
	display: flex;
	align-items: center;
	margin-left: 4px;

	button {
		font-size: 0.6em;
		border: none;
		color: ${(p) => (p.isActive ? 'var(--light-grey)' : 'var(--dark-grey)')};
		background: ${(p) => (p.isActive ? 'var(--navy)' : 'var(--light-grey)')};
		border-radius: ${(p) => (p.isActive ? '0' : '1000px')};
		height: 21px;
		width: 21px;
		padding: 0;
		outline: none;
	}
`;

const Tooltip = styled.div`
	position: absolute;
	z-index: 1;
	top: 100%;
	width: 180px;
	font-size: 12px;
	padding: 12px;
	background: var(--navy);
	color: var(--light-grey);
	right: 0;

	${screenSize.sm} {
		left: inherit;
		width: 150px;
	}
`;

interface FieldHelpProps {
	children: React.ReactNode;
}

export const FieldHelp: React.FC<FieldHelpProps> = ({ children: description }) => {
	const [visible, setVisible] = useState<boolean>(false);

	return (
		<InfoItem>
			{description && (
				<TooltipTrigger isActive={visible}>
					<button type={'button'} onMouseOver={() => setVisible(true)} onMouseOut={() => setVisible(false)}>
						?
					</button>
					{visible && <Tooltip>{description}</Tooltip>}
				</TooltipTrigger>
			)}
		</InfoItem>
	);
};
