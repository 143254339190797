import styled from 'styled-components';

type ButtonColor = 'primary' | 'success' | 'secondary' | 'light';
type ButtonSize = 'small' | 'large';

interface Button {
	size?: ButtonSize;
	color?: ButtonColor;
}

const sizes: { [key in ButtonSize]: any } = {
	small: {
		padding: '5px 10px',
		'font-size': '10px',
	},
	large: {
		padding: '10px 14px',
		'font-size': '14px',
	},
};

const colors: { [key in ButtonColor]: any } = {
	primary: {
		background: '#000080',
		color: '#e4e4e4',
	},
	secondary: {
		background: '#3535E1',
		color: '#f1f1f1',
	},
	success: {
		background: '#219653',
		color: '#E4E4E4',
	},
	light: {
		background: '#e4e4e4',
		color: '#000080',
	},
};

const hoverColors: { [key in ButtonColor]: any } = {
	primary: {
		background: '#3535e1',
		color: '#f1f1f1',
	},
	secondary: {
		background: '#000080',
		color: '#e4e4e4',
	},
	success: {
		background: '#01AF4C',
		color: '#E4E4E4',
	},
	light: {
		background: '#C4C4C4',
		color: '#000080',
	},
};

function findSizeStyle(size: ButtonSize = 'small') {
	return sizes[size];
}

function findColor(color: ButtonColor = 'primary') {
	return colors[color];
}

function findHoverColor(color: ButtonColor = 'primary') {
	return hoverColors[color];
}

export const Button = styled.button<Button>`
	${(p) => findSizeStyle(p.size)};
	${(p) => findColor(p.color)};
	outline: none;
	border-radius: 1000px;
	border: none;
	cursor: pointer;
	transition: background-color 0.2s;
	font-weight: 500;

	&:hover {
		${(p) => findHoverColor(p.color)};
	}
`;
