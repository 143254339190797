import styled from 'styled-components';
import React, { useEffect } from 'react';
import { CellProps, Column, useRowSelect, useSortBy, useTable } from 'react-table';
import { GetMyProjects_myProjects } from '../types/GetMyProjects';

export const Wrapper = styled.table`
	border-collapse: collapse;
	width: 100%;
`;

const TableHead = styled.thead`
	text-align: left;
	border-bottom: 1px solid;

	th {
		padding: 10px 5px;
	}
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
	cursor: ${(p) => (p.onClick ? 'pointer' : 'initial')};

	&:hover {
		background: ${(p) => (p.onClick ? '#DFDFDF' : 'initial')};
	}
`;

const StyledColumn = styled.td`
	padding: 10px 5px;
	border: none;
`;

interface Table<D extends object> {
	data: D[];
	columns: Column<D>[];
	onSelectedRowsChange?: (selection: D[]) => void;
	onRowClick?: (clickedItem: D) => void;
	defaultSortBy?: string;
}

function stopPropagation(ev: React.MouseEvent) {
	ev.stopPropagation();
}

export function Table<D extends object>({ columns, data, onSelectedRowsChange, onRowClick, defaultSortBy }: Table<D>) {
	const { headerGroups, selectedFlatRows, rows, prepareRow } = useTable<D>(
		{
			data,
			columns,
			disableSortRemove: true,
			initialState: {
				sortBy: [
					{
						id: defaultSortBy || '',
						desc: false,
					},
				],
			},
		},
		useSortBy,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				{
					id: 'checkbox',
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<input type={'checkbox'} {...getToggleAllRowsSelectedProps()} />
					),
					Cell: ({ row }: CellProps<GetMyProjects_myProjects>) => (
						<input type={'checkbox'} {...row.getToggleRowSelectedProps()} onClick={stopPropagation} />
					),
				},
				...columns,
			]);
		}
	);

	useEffect(() => {
		onSelectedRowsChange?.(selectedFlatRows.map((row) => row.original));
	}, [selectedFlatRows, onSelectedRowsChange]);

	// console.log(selectedFlatRows);

	return (
		<Wrapper>
			<TableHead>
				{headerGroups.map((headerGroup) => {
					return (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((header) => {
								return (
									<th {...header.getHeaderProps(header.getSortByToggleProps())}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												userSelect: 'none',
											}}
										>
											<span style={{ flex: 0 }}>{header.render('Header')}</span>
											{header.isSorted && (
												<span style={{ paddingLeft: '4px', fontWeight: 'bold' }}>
													{header.isSortedDesc ? '↓' : '↑'}
												</span>
											)}
										</div>
									</th>
								);
							})}
						</tr>
					);
				})}
			</TableHead>

			<TableBody>
				{rows.map((row) => {
					prepareRow(row);

					return (
						<TableRow {...row.getRowProps()} onClick={() => onRowClick?.(row.original)}>
							{row.cells.map((cell) => {
								return <StyledColumn {...cell.getCellProps()}>{cell.render('Cell')}</StyledColumn>;
							})}
						</TableRow>
					);
				})}
			</TableBody>
		</Wrapper>
	);
}
