import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	:root {
		--lighter-grey: #F1F1F1;
		--light-grey: #E4E4E4;
		--dark-grey: #333333;
		--grey: #A3A3A3;
		--navy: #000080;
		--light-blue: #3535E1;
		--dark-blue: #15202B;
		--green: #6FCF97;
  	}
  
	html {
		font-family: 'Manrope', sans-serif;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
		font-size: 14px;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	
	body {
		background: #F2F2F2;
		padding: 0;
		margin: 0;
		font-size: 14px;
	}
	
	h1 {
		font-weight: 600;
		font-size: 50px;
		color: #000080;
	}

	h2 {
		font-weight: normal;
		font-size: 28px;
	}
`;
